*, *:before, *:after {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.background {
  background-image: url(./graphy.png);
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100vw;
}

/*--------------------------------------------------*/

.mainLogo {
  position: absolute;
  height: 100px;
  margin: 2% 0 0 2%;
  z-index: 4;
}

/*--------------------------------------------------*/

.menu {
  background-color: rgb(241, 156, 56);
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.menu button {
  background: none;
  border: none;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  z-index: 4;
}

.menu button img {
  height: 50px;
}

.plusIcon {
  transform: rotate(0deg);
  transition: transform .5s;
}

.xIcon {
  transform: rotate(-315deg);
  transition: transform .5s;
}

.menuOff {
  height: 0;
  opacity: 0;
  transition: height .3s ease;
  overflow-x: hidden;
}

.menuOn {
  height: 75vh;
  opacity: 1;
  width: 100%;
  transition: height .5s ease;
  animation: fadeLanding 2s;
}

.menuOn li {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.menu a {
  color: black;
  text-decoration: none;
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 10px;
  cursor: pointer;
}



/*--------------------------------------------------*/


.footerContainer {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(241, 156, 56);
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 45px;
}

.footerContainer p {
  text-align: center;
  font-weight: 800;
}

.notices {
  display: flex;
  justify-content: center;
}

.footerContainer a {
  margin-left: 15px;
  margin-right: 15px;
  color: black;
  font-weight: 500;
}

/*--------------------------------------------------*/


.landingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 550px;
  margin-top: 190px;
  padding: 15px;
}

#nameHeading {
  width: 35vh;
  opacity: 0;
  animation: fadeLanding 2s forwards;
  animation-delay: 2s;
  margin-bottom: 50px;
}

#avatar {
  height: 25vh;
  align-self: center;
}

.subline {
  height: 7vh;
  margin-bottom: 15px;
  opacity: 0;
  animation: fadeLanding 1s forwards;
}

#coder {
  animation-delay: 4s;
}

#designer {
  animation-delay: 5s;
}

#websiter {
  animation-delay: 6s;
}

#snoop {
  position: absolute;
  height: 10%;
  top: 75px;
  right: 75px;
  animation: fadeLanding 1s forwards;
  animation-delay: 7s;
  opacity: 0;
  z-index: 0;
}

@keyframes fadeLanding {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*--------------------------------------------------*/

.aboutPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 300px;
}

#aboutAvatar {
  height: 25vh;
}

.aboutPage ul {
  list-style-type: none;
  margin: 0 10% 0 10%;
}

.aboutPage article {
  margin: 4% 5% 2% 5%;
}

.aboutPage h1 {
  margin-top: 50px;
}

.aboutPage article p {
  padding: 15px;
}

.social {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.social img {
  height: 52px;
}

#gh {
  height: 32px;
}

#li {
  height: 48px;
}

#emailLink {
  height: 40px;
}

#resume {
  height: 60px;
}


/*--------------------------------------------------*/

.projects {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.projects h4 {
  color: green;
}


.projects hr {
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom : 50px;
  color: rgba(0, 0, 0, 0.2);
  height: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
}
/*--------------------------------------------------*/

.scarlett, .dmc, .chloe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.scarlett {
  margin-top: 200px;
  margin-bottom: 50px;
}

#scarlettLogo, #dmcLogo, #chloeLogo {
  width: 50%;
}

.scarlettMedia, .dmcMedia, .chloeMedia {
  width: 100%;
  display: flex;
  justify-content: center;
}

.scarlettMedia {
  margin-top: 10%;
}

.scarlettMedia img, .dmcMedia img, .chloeMedia img {
  width: 90%;
}

.scarlett article, .dmc article, .chloe article {
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scarlett article h2, .dmc article h2, .chloe article h2 {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.scarlett article h3, .dmc article h3, .chloe article h3 {
  margin-top: 10%;
  font-weight: 300;
}

.scarlett article h4, .dmc article h4, .chloe article h4 {
  text-align: center;
  margin-top: 5%;
}

.scarlett article ul, .dmc article ul, .chloe article ul {
  padding-left: 5%;
  list-style-type: circle;
  margin-bottom: 10%;
}

.scarlett article li, .dmc article li, .chloe article li {
  font-weight: 300;
}

/*--------------------------------------------------*/

.dmc {
  margin-top: 25px;
  margin-bottom: 50px;
}

.dmcMedia {
  width: 100%;
  display: flex;
  justify-content: center;
}

.dmcMedia img {
  margin-top: 10%;
}


/*--------------------------------------------------*/


.chloe article {
  margin-bottom: 20%;
}

#chloeLogo {
  margin-bottom: 5%;
}


/*--------------------------------------------------*/

.viewCode {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.viewCode h1 {
  font-size: 2rem;
  padding: 0;
  margin: 0 0 10px 0;
}

.viewCode a {
  display: flex;
  color: black;
  align-items: center;
  text-decoration: none;
}

.viewCode p {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1rem;
  font-weight: 800;
  border-bottom: 2px solid rgb(134, 134, 134);
  color: rgb(0, 0, 0);
}

/*--------------------------------------------------*/

.contactPage {
  display: flex;
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formHeader {
  display: flex;
  font-size: 2.5rem;
  margin-top: 150px;
  margin-bottom: 20px;
}

.email {
  height: 3rem;
  margin-left: .75rem;
}

.entFormCont {
  display: flex;
  margin-bottom: 150px;
}

.fieldInput, .fieldText {
  outline: none;
  border: 0;
  padding: 15px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.4); 
  border-radius: 4px;
  margin-bottom: 10px;
  width: 800px;
  font-size: 1rem;
  background-color: white;
  max-width: 95vw;
  -webkit-appearance: none;
}

.textArea {
  resize: none;
  height: 250px;
}

.fieldInput:focus, .fieldText:focus {
  border-bottom: 2px solid rgb(0, 32, 158);
  transition: border 0.15s ease;
}

.fieldInput:not(:focus), .fieldText:not(:focus) {
  border-bottom: 1px solid black;
  transition: border 0.15s ease;
}

.touched {
  border-left: 3px solid red;
}

.notTouched {
  border-left: 0px solid transparent;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
  align-items: center;
  height: 40px;
}

.redMessage {
  font-size: 1rem;
  color: red;
}

.greenMessage {
  font-size: 1rem;
  color: green;
}

.greyMessage {
  font-size: 1rem;
  color: grey;
}

.submitButton {
  display: flex;
  border: none;
  background: none;
  color: white;
  background-color: green;
  padding: 8px;
  border-radius: 3px;
  font-size: 1rem;
  cursor: pointer;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.submitButton:active {
  box-shadow: inset 0px 3px 4px 0px rgba(0, 0, 0, 0.4);
}

.submitButton:disabled {
  cursor: not-allowed;
  box-shadow: none;
  background-color: grey;
}

.hideSubmitButton {
  opacity: 0;
}

.recaptcha {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 15px;
}

/*---------------------------------------------------------------*/

/*--TABLET--*/

@media screen and (min-width: 450px) and (max-width: 1079px) {
  .bio {
    width: 65vw;
  }

  .aboutPage h1 {
    font-size: 2rem;
  }

  .bio p {
    font-size: 1.5rem;
  }

  .social {
    width: 50vw;
  }
}


/*--DESKTOP--*/

@media screen and (min-width: 1080px) {

  .menuOn {
    height: 50vh;
  }

  .menu a:hover {
    border-bottom: 10px solid black;
  }

  /*------------*/


  .bio {
    width: 65vw;
  }

  .aboutPage h1 {
    font-size: 2rem;
  }

  .bio p {
    font-size: 1.5rem;
  }

  .social {
    width: 50vw;
  }

  /*------------*/

  .content ul {
    width: 20%;
  }

  /*------------*/

  .scarlettMedia {
    margin-top: 100px;
  }

  .dmcMedia {
    margin-top: 0px;
  }

  .scarlett article, .dmc article, .chloe article {
    width: 50vw;
  }

  .scarlett article h2, .dmc article h2, .chloe article h2 {
    font-size: 2.5rem;
    text-align: center;
  }

  .scarlett article h3, .dmc article h3, .chloe article h3 {
    font-size: 2rem;
  }

  .scarlett article h4, .dmc article h4, .chloe article h4 {
    font-size: 2rem;
  }

  .scarlett article li, .dmc article li, .chloe article li {
    font-size: 1.5rem;
  }

  .viewCode h1 {
    font-size: 3rem;
  }

  .viewCode p {
    font-size: 2rem;
  }

  /*------*/



}

@media screen and (max-height: 700px) {
  .menuOn {
    height: 100vh;
  }
}

@media screen and (min-height: 701px) and (max-height: 885px) {
  .menuOn {
    height: 90vh;
  }
}

@media screen and (min-height: 1190px) {
  .content {
    margin-top: 500px;
    margin-bottom: 800px;
  }
  
  .aboutPage {
    margin-top: 300px;
    margin-bottom: 700px;
  }
}

@media screen and (min-width: 3000px) {
  .content {
    margin-top: 300px;
    margin-bottom: 800px;
  }

  .dmc {
    margin-top: 5%;
  }
}